import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'
import { apolloClient } from '../client'

import { useIsChangingRoute } from '@docpace/shared-react-hooks/useIsChangingRoute'
import { useSessionSharing } from '@docpace/shared-react-hooks/useSessionSharing'

import '@docpace/apps/reception-status-app/tailwind.css'
import '@docpace/shared-react-styles/shared/globals.css'
import '@docpace/shared-react-styles/shared/materialUI.css'
import '@docpace/shared-react-styles/shared/cards.css'
import '@docpace/shared-react-styles/shared/tables.css'
import '@docpace/shared-react-styles/shared/layout.css'

import './styles.css'

function ReceptionStatusApp({ Component, pageProps }: AppProps) {
    useIsChangingRoute()
    const { isInitialized } = useSessionSharing()

    return (
        <ApolloProvider client={apolloClient}>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Head>
                    <title>DOCPACE® Provider Status Display</title>
                    <meta
                        name="viewport"
                        content="initial-scale=1.0, width=device-width"
                    />
                </Head>
                <main className="app">
                    {isInitialized && <Component {...pageProps} />}
                </main>
            </SnackbarProvider>
        </ApolloProvider>
    )
}

export default ReceptionStatusApp
